@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */

/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
    width: 1em;
    height: 1em;
  }

  .svg-icon path,
  .svg-icon polygon,
  .svg-icon rect {
    fill: #fff;
  }

  .svg-icon circle {
    stroke: #fff;
    stroke-width: 1;
  }

  .ckeditor blockquote {
  border-left: 5px solid rgba($color: #fff, $alpha: 0.40);
  padding-left: calc(5px + 1em);
  font-style: italic;
  margin: 0.75em 0;
}

.ckeditor ul, .ck.ck-editor__editable_inline ul {
  margin-left: 2em;
  list-style-type: disc;
}

/*heading 1*/
.ckeditor h2, .ck.ck-editor__editable_inline h2 {
  font-size: x-large;
  margin: 1em 0;
}
/*heading 2*/
.ckeditor h3, .ck.ck-editor__editable_inline h3 {
  font-size: larger;
  margin: 0.75em 0;
}
/*heading 3*/
.ckeditor h4, .ck.ck-editor__editable_inline h4 {
  font-size: large;
  margin: 0.5em 0;
}

